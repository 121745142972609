import React from 'react';
import s from './ComplaintsAndAppeals.module.scss';

const ComplaintsAndAppeals = () => {
    return (
        <article className={s.article_wrapper}>
            <section className={s.section}>
                <section>
                    <h1>Порядок рассмотрения жалоб и апелляций</h1>

                    <p>
                        <b>1.</b> Заявитель жалобы подает жалобу в письменном виде непосредственно на имя руководителя органа по сертификации-директора ООО «ЮЛТА-комплекс» Сысоева И.Е., в которой:
                        <span>- излагает предмет жалобы;</span>
                        <span>- определяет потенциальные стороны-участники конфликта;</span>
                        <span>- описывает участие заявителя в событиях, которые предшествовали заявлению и стали причиной обращения с жалобой в орган по сертификации;</span>
                        <span>- предлагает решение (в случае необходимости).</span>
                    </p>
                    <p>
                        <b>2.</b> Апелляция в письменном виде должна:
                        <span>- определять сторону, в отношении которой она подается или сторон участников разбирательства, их почтовые адреса, телефоны, адреса электронной почты, иные координаты для связи;</span>
                        <span>- содержать изложение предмета спора;</span>
                        <span>- описывать имевшие, на момент подачи апелляции, решения и иные результаты контактов и слушаний;</span>
                        <span>- описывать заинтересованность стороны-заявителя в урегулировании спора и причину обращения в Комитет по беспристрастности;</span>
                        <span>- содержать согласие заявителя о рассмотрении поданного заявления Комитетом по обеспечению беспристрастности и обязательства исполнить или при несогласии обжаловать решение Комитета по беспристрастности в вышестоящей инстанции;</span>
                        <span>- быть подписана уполномоченным лицом с приложением доказательств таких полномочий;</span>
                        <span>- включать приложения, содержащие документальные или иные доказательства, на которых основана апелляция и список, в котором перечисляются такие доказательства и/или документы.</span>
                    </p>
                    <p>
                        <b>3.</b> В органе по сертификации осуществляется регистрация жалобы или апелляции и передается на рассмотрение руководителю органа по сертификации в течение 2-х рабочих дней с момента поступления.
                    </p>
                    <p>
                        <b>4.</b> Руководитель органа по сертификации подтверждает, что жалоба или апелляция относится к деятельности органа по сертификации и принимает жалобу или апелляцию к рассмотрению.
                        <br/>В случае если жалоба или апелляция относится к деятельности органа по сертификации, решением руководителя органа по сертификации назначается ответственный специалист по работе с жалобой или апелляцией из персонала, не принимавшего участия в работах по оценке соответствия, связанных с жалобой или апелляцией.
                        <br/>На рассмотрение жалобы или апелляцией отводится 30 дней. Отсчет времени начинается с момента получения жалобы или апелляции и регистрации его в журнале регистрации жалоб и апелляций.
                        <br/>Руководитель органа по сертификации имеет право отклонить жалобу или апелляцию, которая не относится к деятельности органа по сертификации. Отказ должен быть изложен в письменном виде и объяснять причину отказа. Уведомление об отказе в принятии и рассмотрении подготавливается назначенным руководителем органа по сертификации ответственным исполнителем и должно быть отправлено не позднее 3-х дней с момента принятия соответствующего решения.
                        <br/>Решение об отказе принять жалобу или апелляцию как несоответствующую к его подаче, может быть обжаловано в Комитете по беспристрастности.
                    </p>
                    <p>
                        <b>5.</b> Первоначальный ответ заявителю о принятии жалобы или апелляции к рассмотрению составляет ответственный специалист по работе с жалобой или апелляцией не позднее 7 дней с момента поступления жалобы или апелляции. Ответ должен содержать:
                        <span>- информацию о получении жалобы или апелляции;</span>
                        <span>- информацию о регистрации и номер регистрации жалобы или апелляции;</span>
                        <span>- информацию о сроках рассмотрения жалобы или апелляции.</span>
                    </p>
                    <p>
                        <b>6.</b> Орган по сертификации несет ответственность за сбор и верификацию всей необходимой информации (насколько это возможно) для принятия решения в отношении жалобы или апелляции.
                        <br/>После регистрации ответственный исполнитель анализирует ситуацию, изложенную в заявлении, проводит проверку с целью выявления внутренних и/или внешних причин появления жалобы, рассматривает все материалы, имеющиеся в органе по сертификации, проверяет соблюдение сроков и порядка проведения работ по сертификации.
                        <br/>В том случае, когда жалоба подана на продукцию, сертифицированную органом по сертификации, ответственный исполнитель в обязательном порядке информирует заявителя на сертификацию (владельца сертификата) с требованием пояснить ситуацию. Если в ходе рассмотрения заявитель жалобы и сторона, на которую подана жалоба, урегулируют свой спор, ответственный исполнитель готовит проект соответствующего решения.
                        <br/>В случае, когда сторона-ответчик (производитель или поставщик продукции) не отвечает на вопросы органа по сертификации и не идет на обоснованное сотрудничество с целью урегулирования спора, орган по сертификации имеет право на проведение внеплановой периодической оценки, приостановление или отмену (прекращение) действия сертификата соответствия.
                    </p>
                    <p>
                        <b>7.</b> Ответственный специалист докладывает результаты рассмотрения жалоб на Совете по сертификации, апелляций - на заседании Комитета по беспристрастности. На заседание Совета по сертификации и Комитета по беспристрастности, при необходимости, приглашаются представители стороны, направившей жалобу или апелляцию. Принятое на заседании Совета по сертификации или Комитета по беспристрастности решение оформляется документально.
                    </p>
                    <p>
                        <b>8.</b> Орган по сертификации официально уведомляет заявителя жалобы о результате и окончании процесса рассмотрения жалобы.
                        <br/>Ответ в письменном виде направляется заявителю  не позднее 4-х недель с момента поступления и регистрации жалобы, с указанием принятых мер.
                        <br/>В случае если заявитель соглашается с предложенными мероприятиями, или же ответ от заявителя не поступает в течение 2-х недель с момента направления соответствующего письма, после выполнения корректирующих и предупреждающих действий жалоба закрывается. Ответственный исполнитель сообщает о закрытии жалобы руководителю органа и делает отметку в журнале регистрации жалоб и апелляций.
                    </p>
                    <p>
                        <b>9.</b> Орган по сертификации официально уведомляет апеллянта о результате и окончании процесса рассмотрения апелляции. Информация по принятому Комитетом по беспристрастности решению должна быть направлена заявителю апелляции в течение двух дней после заседания Комитета по беспристрастности.
                        <br/>В случае если в течение двух недель с момента отправки письма от заявителя апелляции поступает письмо о несогласии, Орган по сертификации обращается в вышестоящую организацию, где принимается окончательное решение, о чем информируется апеллянт.
                    </p>
                </section>
            </section>
        </article>
    )
};

export default ComplaintsAndAppeals;