import React from 'react';
import s from './DecInformation.module.scss';

const DecInformation = () => {
    return (
        <article className={s.article_wrapper}>
            <section className={s.section}>
                <section>
                    <h1>Информация для Заявителей</h1>

                    <h2>Права и обязанности лиц, принимающих декларацию о соответствии</h2>
                    <p>
                        <b>1. </b>Лицо, принимающее декларацию, <b>имеет право</b>:
                        <span><b>1.1. </b>выбирать любую схему подтверждения соответствия, применяемую при декларировании соответствия, из числа схем, предусмотренных для этого объекта оценки соответствия с учетом условий их применения;  </span>
                        <span><b>1.2. </b>определять конкретный состав доказательственных материалов в отношении определенного объекта оценки соответствия с учетом выбранной схемы подтверждения соответствия и требований к составу доказательственных материалов, определенных техническим регламентом Республики Беларусь или техническим регламентом Евразийского экономического союза, а в случаях, если технические регламенты Республики Беларусь или технические регламенты Евразийского экономического союза отсутствуют либо если состав доказательственных материалов ими не определен, – правилами подтверждения соответствия;</span>
                        <span><b>1.3. </b>подавать в орган по регистрации деклараций в письменной форме заявление о регистрации декларации, если иное не предусмотрено техническими регламентами Евразийского экономического союза и иным правом Евразийского экономического союза;</span>
                        <span><b>1.4. </b>подавать в орган по регистрации деклараций в письменной форме заявление о прекращении действия регистрации декларации о соответствии, уведомление о прекращении действия декларации о соответствии техническим регламентам Евразийского экономического союза или заявление о прекращении действия декларации о соответствии по единой форме;</span>
                        <span><b>1.5. </b>применять иные знаки соответствия Национальной системы подтверждения соответствия Республики Беларусь для маркировки продукции, в отношении которой имеется зарегистрированная декларация о соответствии, в порядке, установленном правилами подтверждения соответствия;</span>
                        <span><b>1.6. </b>обжаловать в вышестоящую по отношению к органу по регистрации деклараций организацию (при ее наличии) и (или) в орган по аккредитации, если органом по регистрации деклараций является орган по сертификации, решения и (или) действия (бездействие) органа по регистрации деклараций, а в случае их отсутствия, несогласия с их решением либо неполучения ответа в тридцатидневный срок – в суд;</span>
                        <span><b>1.7. </b>осуществлять иные права в соответствии с Законом Республики Беларусь от 24 октября 2016 г. № 437-З «Об оценке соответствия техническим требованиям и аккредитации органов по оценке соответствия», правилами подтверждения соответствия, иными актами законодательства Республики Беларусь, техническими регламентами Евразийского экономического союза и иным правом Евразийского экономического союза, а также с международными договорами Республики Беларусь, не составляющими право Евразийского экономического союза.</span>
                    </p>
                    <p>
                        <b>2. </b>Лицо, принимающее декларацию, <b>несёт ответственность</b> за:
                        <span><b>2.1. </b>выполнение требований правил подтверждения соответствия, иных нормативных правовых актов Национальной системы подтверждения соответствия Республики Беларусь и права Евразийского экономического союза, касающиеся проведения декларирования соответствия и регистрации деклараций;</span>
                        <span><b>2.2. </b>представление Исполнителю всех необходимых материалов и документов по запросу уполномоченного органа.</span>
                        <span><b>2.3. </b>достоверность сведений, приведенных в документах, представленных Исполнителю</span>
                        <span><b>2.4. </b>обеспечение соответствия продукции, в отношении которой принята и зарегистрирована декларация, техническим требованиям, содержащимся в актах (документах), указанных в декларации;</span>
                        <span><b>2.5. </b>применение знака соответствия техническому регламенту Республики Беларусь для маркировки продукции, в отношении которой проведены все установленные техническими регламентами Республики Беларусь процедуры подтверждения соответствия и которая соответствует техническим требованиям всех распространяющихся на эту продукцию технических регламентов Республики Беларусь, в порядке, установленном техническим регламентом Республики Беларусь, а в случае, если в нем такой порядок не установлен, – правилами подтверждения соответствия;</span>
                        <span><b>2.6. </b>применение единого знака обращения продукции на рынке Евразийского экономического союза для маркировки продукции, соответствующей техническим требованиям технических регламентов Евразийского экономического союза, распространяющихся на эту продукцию, и прошедшей установленные техническими регламентами Евразийского экономического союза процедуры оценки соответствия, в соответствии с правом Евразийского экономического союза;</span>
                        <span><b>2.7. </b>в случаях, предусмотренных пунктами 4 и 5 статьи 31 Закона Республики Беларусь от 24 октября 2016 г. № 437-З «Об оценке соответствия техническим требованиям и аккредитации органов по оценке соответствия» или правом Евразийского экономического союза, за подачу в орган по регистрации деклараций в письменной форме заявление о прекращении действия регистрации декларации о соответствии, действия декларации о соответствии техническим регламентам Евразийского экономического союза или действия декларации о соответствии по единой форме;</span>
                        <span><b>2.8. </b>приостановление или прекращение выпуска продукции в обращение, в отношении которой имеется зарегистрированная декларация, если эта продукция не соответствует техническим требованиям, содержащимся в актах (документах), указанных в декларации.</span>
                    </p>
                </section>
            </section>
        </article>
    )
};

export default DecInformation;