import React from 'react';
import s from './MarksOfConformity.module.scss';

const MarksOfConformity = () => {
    return (
        <article className={s.article_wrapper}>
            <section className={s.section}>
                <section>
                    <h1>Применение знаков соответствия, знаков аккредитации</h1>

                    <h2>ЗНАКИ СООТВЕТСТВИЯ
                        <br/>НАЦИОНАЛЬНОЙ СИСТЕМЫ ПОДТВЕРЖДЕНИЯ СООТВЕТСТВИЯ
                        <br/>РЕСПУБЛИКИ БЕЛАРУСЬ
                    </h2>
                    <p>
                        Виды, описание и порядок применения знаков соответствия Национальной системы подтверждения соответствия Республики Беларусь, за исключением знака соответствия техническому регламенту Республики Беларусь, определены в Главе 38 Правил подтверждения соответствия Национальной системы подтверждения соответствия Республики Беларусь, утверждённых Постановлением Госстандарта от 25.07.2017 № 61.
                    </p>
                    <p>
                        Знаки соответствия предназначаются для информирования потребителя и других заинтересованных сторон о проведении всех необходимых процедур подтверждения соответствия продукции, выполнения работ, оказания услуг, компетентности персонала, систем управления (менеджмента) и иных объектов оценки, а также о соответствии маркированных ими объектов оценки соответствия техническим требованиям всех документов, распространяющихся на эти объекты оценки, указанных в документах об оценке соответствия.
                    </p>

                    <h2>ЕДИНЫЙ ЗНАК ОБРАЩЕНИЯ ПРОДУКЦИИ
                        <br/>НА РЫНКЕ ЕВРАЗИЙСКОГО ЭКОНОМИЧЕСКОГО СОЮЗА
                    </h2>
                    <p>
                        Единый знак обращения продукции на рынке Евразийского экономического союза свидетельствует о том, что продукция, маркированная им, прошла все установленные в технических регламентах Евразийского экономического союза (технических регламентах Таможенного союза) процедуры оценки (подтверждения) соответствия и соответствует требованиям всех распространяющихся на данную продукцию технических регламентов Евразийского экономического союза (технических регламентах Таможенного союза).
                    </p>
                    <p>
                        Правила применения, форма и размеры единого знака обращения продукции на рынке Евразийского экономического союза определены в «Порядке применения единого знака обращения продукции на рынке Евразийского экономического союза», утв. Решением Комиссии Таможенного союза от 15.07.2011 № 711.
                    </p>

                    <h2>ЗНАК СООТВЕТСТВИЯ
                        <br/>ТЕХНИЧЕСКОМУ РЕГЛАМЕНТУ РЕСПУБЛИКИ БЕЛАРУСЬ
                    </h2>
                    <p>
                        Продукция, прошедшая все установленные соответствующими техническими регламентами Республики Беларусь процедуры оценки соответствия, что должно быть подтверждено документами, предусмотренными для соответствующих форм оценки соответствия в Национальной системе подтверждения соответствия Республики Беларусь, должна быть маркирована знаком соответствия техническому регламенту Республики Беларусь.
                    </p>
                    <p>
                        Описание и порядок применения знака соответствия техническому регламенту Республики Беларусь установлен в Постановлении Совета Министров Республики Беларусь от 24.05.2017 № 383 «Об описании и порядке применения знака соответствия техническому регламенту Республики Беларусь и признании утратившим силу постановления Совета Министров Республики Беларусь от 21 июня 2011 г. № 810».
                    </p>

                    <h2>ЗНАК АККРЕДИТАЦИИ</h2>
                    <p>
                        Правила и порядок применения знака аккредитации, а также содержание и линейные размеры определены Правилами аккредитации, утв. Постановлением Государственного комитета по стандартизации Республики Беларусь от 31.05.2011 № 27.
                    </p>
                    <p>
                        Знаки аккредитации предоставлены БГЦА органу по сертификации продукции ООО «ЮЛТА-комплекс» в порядке определенном п. 7.4.1 ДП СМ 4.3-2020 «Правила применения знака аккредитации, комбинированных знаков ILAC MRA и IAF MLA, текстовых ссылок на аккредитацию и на статус подписанта EA BLA, ILAC MRA, IAF MLA» для применения с целью идентификации статуса аккредитации.
                    </p>
                    <p>
                        Орган по сертификации продукции ООО «ЮЛТА-комплекс» несет ответственность за ненадлежащее и неправильное применение знака аккредитации в рамках предоставленной области аккредитации.
                    </p>
                    <p>
                        Потребителям услуг аккредитованного органа по сертификации ООО «ЮЛТА-комплекс» не допускается применение знака аккредитации или ссылки на аккредитацию потребителями услуг аккредитованного органа по сертификации ООО «ЮЛТА-комплекс».
                    </p>

                    <h2>Потребители услуг аккредитованного органа по сертификации
                        <br/>ООО «ЮЛТА-комплекс» должны:
                    </h2>
                    <p>
                        - при применении знаков соответствия Национальной системы подтверждения соответствия Республики Беларусь соблюдать требования Правил подтверждения соответствия Национальной системы подтверждения соответствия Республики Беларусь, утверждённых Постановлением Государственного комитета по стандартизации Республики Беларусь от 25.07.2017 № 61;
                    </p>
                    <p>
                        - при маркировке продукции единым знаком обращения продукции на рынке Евразийского экономического союза соблюдать требования решения комиссии Таможенного союза от 15.07.2011 № 711 «О едином знаке обращения продукции на рынке государств – членов Таможенного союза»;
                    </p>
                    <p>
                        - при маркировке знаком соответствия техническому регламенту Республики Беларусь соблюдать требования Постановления Совета Министров Республики Беларусь от 24.05.2017 № 383 «Об описании и порядке применения знака соответствия техническому регламенту Республики Беларусь и признании утратившим силу постановления Совета Министров Республики Беларусь от 21 июня 2011 г. № 810»;
                    </p>
                    <p>
                        - выполнять требования сертификации, в том числе при ссылке на сертификат соответствия и знак соответствия в таких средствах информации, как документы, брошюры или рекламные проспекты, а так же требования к продукции,  включая реализацию соответствующих изменений, доведенных до него органом по сертификации;
                    </p>
                    <p>
                        - не использовать документы об оценке соответствия таким образом, который может нанести ущерб репутации органа по сертификации, а также не делать никаких заявлений в отношении сертификации своей продукции, которые вводят в заблуждение или являются неправомерными;
                    </p>
                    <p>
                        - в случае приостановления, отмены или прекращения действия сертификата соответствия прекратить использование всех рекламных материалов, которые содержат какие-либо ссылки на документы, подтверждающие результаты сертификации.
                    </p>
                </section>
            </section>
        </article>
    )
};

export default MarksOfConformity;