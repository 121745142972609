import React from 'react';
import s from './CertInformation.module.scss';

const CertInformation = () => {
    return (
        <article className={s.article_wrapper}>
            <section className={s.section}>
                <section>
                    <h1>Информация для Заявителей</h1>

                    <h2>Права и обязанности Заявителей на проведение сертификации</h2>
                    <p>
                        <b>1. </b>Заявитель на проведение сертификации <b>имеет право</b>:
                        <span><b>1.1. </b>заключить договор на выполнение работ по сертификации при согласии с основными условиями предстоящей сертификации;</span>
                        <span><b>1.2. </b>подавать в орган по сертификации в устной либо письменной форме заявление о выдаче сертификата;</span>
                        <span><b>1.3. </b>обжаловать в вышестоящую по отношению к органу по сертификации организацию (при ее наличии) и (или) в орган по аккредитации решения и (или) действия (бездействие) органа по сертификации, а в случае  несогласия с их решением либо неполучения ответа в тридцатидневный срок – в суд;</span>
                        <span><b>1.4. </b>осуществлять иные права в соответствии с Законом Республики Беларусь от 24 октября 2016 г. № 437-З «Об оценке соответствия техническим требованиям и аккредитации органов по оценке соответствия», правилами подтверждения соответствия, иными актами законодательства Республики Беларусь, техническими регламентами Евразийского экономического союза и иным правом Евразийского экономического союза, а также с международными договорами Республики Беларусь, не составляющими право Евразийского экономического союза.</span>
                    </p>
                    <p>
                        <b>2. </b>Заявитель на проведение сертификации <b>несёт ответственность</b> за:
                        <span><b>2.1. </b>представление Исполнителю всех необходимых материалов и документов по запросу органа по сертификации. </span>
                        <span><b>2.2. </b>достоверность сведений, приведенных в документах, представленных Исполнителю. </span>
                        <span><b>2.3. </b>выполнение требований правил подтверждения соответствия, иных нормативных правовых актов Национальной системы подтверждения соответствия Республики Беларусь, включая требования к продукции, подтвержденные при сертификации, и реализацию изменений, доведенных органом по сертификации; </span>
                        <span><b>2.4. </b>создание необходимых условий для работы экспертов-аудиторов и технических экспертов по сертификации при выполнении работ по сертификации. </span>
                    </p>

                    <h2>Права и обязанности Владельцев сертификатов соответствия:</h2>
                    <p>
                        <b>1. </b>Владелец сертификата <b>имеет право</b>:
                        <span><b>1.1. </b>обращаться в орган по сертификации с заявкой на повторную сертификацию;</span>
                        <span><b>1.2. </b>подавать в орган по сертификации в письменной форме заявления о внесении изменений и (или) дополнений в сертификат соответствия, выдаче дубликата сертификата, прекращении действия сертификата, а также в устной либо письменной форме заявление о выдаче сертификата при повторной сертификации;</span>
                        <span><b>1.3. </b>применять иные знаки соответствия Национальной системы подтверждения соответствия Республики Беларусь для маркировки сертифицированной продукции в порядке, установленном правилами подтверждения соответствия;</span>
                        <span><b>1.4. </b>предъявлять претензии к сертификации согласно области сертификации.</span>
                    </p>
                    <p>
                        <b>2. </b>Владелец сертификата соответствия <b>обязан</b>:
                        <span><b>2.1. </b>применять знак соответствия техническому регламенту Республики Беларусь для маркировки сертифицированной продукции;</span>
                        <span><b>2.2. </b>заключать договоры на выполнение работ по проведению периодической оценки сертифицированной продукции в соответствии с периодичностью такой оценки;</span>
                        <span><b>2.3. </b>создавать необходимые условия для работы экспертов-аудиторов и технических экспертов по сертификации, включая предоставление на рассмотрение документов и записей, а также доступа к соответствующему оборудованию, местоположению, территории, персоналу и субподрядчикам заказчика; для участия наблюдателей, при необходимости, при исполнении договора на выполнение работ по проведению периодической оценки сертифицированной продукции;</span>
                        <span><b>2.4. </b>обеспечивать соответствие сертифицированной продукции требованиям ТНПА, указанным в сертификате соответствия;</span>
                        <span><b>2.5. </b>выполнять требования правил подтверждения соответствия, иных нормативных правовых актов Национальной системы подтверждения соответствия Республики Беларусь, касающиеся проведения сертификации;</span>
                        <span><b>2.6. </b>оплачивать все расходы, связанные с осуществлением периодической оценки сертифицированной продукции;</span>
                        <span><b>2.7. </b>незамедлительно информировать орган по сертификации обо всех изменениях продукции и (или) процесса ее производства, влияющих на безопасность продукции, изменениях организационной структуры организации и юридического адреса, а также, в случае необходимости, о факте прекращения своей деятельности как юридического лица.</span>
                        <span><b>2.8. </b>принимать соответствующие меры по отношению к жалобам и любым недостаткам, обнаруженным в продукции, которые оказывают влияние на несоблюдение требований сертификации, и документировать предпринятые действия. Вести учет всех рекламаций (претензий) на сертифицированную продукцию и информировать о них орган по сертификации;</span>
                        <span><b>2.9. </b>передавать копии сертификатов соответствия или подлинники сертификатов соответствия юридическим лицам и индивидуальным предпринимателям, реализующим продукцию, указанную в сертификате соответствия; копии документов о сертификации, которые владелец сертификата предоставляет другим лицам, должны быть воспроизведены полностью или как указано в схеме сертификации.</span>
                        <span><b>2.10. </b>вести учет копий сертификатов соответствия с указанием наименований юридических лиц и индивидуальных предпринимателей, которым они выдавались;</span>
                        <span><b>2.11. </b>использовать сертификат соответствия в соответствии с правилами его применения и строго на продукцию, на которую получен сертификат;</span>
                        <span><b>2.12. </b>приостанавливать или прекращать выпуск продукции в обращение, если сертифицированная продукция не соответствует техническим требованиям, указанным в сертификате соответствия, прекратить ссылаться на сертификат и не применять иные знаки соответствия Национальной системы подтверждения соответствия Республики Беларусь для маркировки сертифицированной продукции, если она не соответствует техническим требованиям, содержащимся в сертификате соответствия;</span>
                        <span><b>2.13. </b>не использовать сертификацию продукции таким образом, который может нанести ущерб репутации органа по сертификации, а также не делать никаких заявлений в отношении сертификации своей продукции, которые орган по сертификации посчитает вводящими в заблуждение или неправомерными;</span>
                        <span><b>2.14. </b>соблюдать требования, которые установлены органом по сертификации или указаны в схеме сертификации, при ссылке на сертификацию своей продукции в таких средствах информации, как документы, брошюры или рекламные проспекты.</span>
                        <span>Владелец сертификата соответствия несет установленную законодательством Республики Беларусь ответственность за поставку (реализацию) сертифицированной и маркированной знаком соответствия продукции, не отвечающей требованиям, установленным в ТНПА, указанных в сертификате соответствия.</span>
                    </p>
                </section>
            </section>
        </article>
    )
};

export default CertInformation;